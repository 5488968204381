import './App.scss';
import logo from './logo.png';

import React from 'react';

import {
  Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { Auth0Context } from "@auth0/auth0-react";

import { createBrowserHistory } from 'history';

import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavDropdown,
  Spinner
} from 'react-bootstrap';

import  { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faUsers,
  faBullseye,
  faGraduationCap,
  faChartLine,
  faClipboardCheck,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';

import api_url from "./api_url";

// Every menu-visible product should have a corresponding ReactJS Component.

import InvokeWelcome from "./Components/InvokeWelcome";
import InvokeDEI from "./Components/InvokeDEI";
import InvokeEngagement from "./Components/InvokeEngagement";
import InvokeEnrollment from "./Components/InvokeEnrollment";
import InvokeEnrollmentTracker from "./Components/InvokeEnrollmentTracker";
import InvokeRetention from "./Components/InvokeRetention";
import InvokeStudentSuccess from "./Components/InvokeStudentSuccess";
import InvokeAnalysisResult from './Components/InvokeAnalysisResult'

export const history = createBrowserHistory();

class App extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);

    this.menuProducts = {
      InvokeDEI: {
        component: <InvokeDEI />,
        icon: <FontAwesomeIcon icon={faUsers} fixedWidth />
      },
      InvokeEngagement: {
        component: <InvokeEngagement />,
        icon: <FontAwesomeIcon icon={faBullseye} fixedWidth />
      },
      InvokeEnrollment: {
        component: <InvokeEnrollment />,
        icon: <FontAwesomeIcon icon={faGraduationCap} fixedWidth />
      },
      InvokeEnrollmentTracker: {
        component: <InvokeEnrollmentTracker />,
        icon: <FontAwesomeIcon icon={faChartLine} fixedWidth />
      },
      InvokeRetention: {
        component: <InvokeRetention />,
        icon: <FontAwesomeIcon icon={faClipboardCheck} fixedWidth />
      },
      InvokeStudentSuccess: {
        component: <InvokeStudentSuccess />,
        icon: <FontAwesomeIcon icon={faThumbsUp} fixedWidth />
      }
    };

    this.state = {
      jrrToken: null,
      licenses: {}
    };
  }

  componentDidMount() {
    const {getAccessTokenSilently} = this.context;
    getAccessTokenSilently({
      audience: api_url,
    }).then((t) => {
      this.setState({ jrrToken: "Bearer " + t });
      this.getLicenses();
    }).catch((e) => {
      console.log(e);
    });
  }

  // Retrieve a list of products authorized for this account.
  getLicenses() {
    const options = {
      mode: 'cors',
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.jrrToken
      }
    };

    fetch(
      api_url + "/license",
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {
        this.setState({licenses: data})
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  render() {

    let linkList = [];
    let routeList = [];

    // Check to see if we've got a list of licensed products
    // available. If so, generate both product menu and the
    // routes.
    if (typeof this.state.licenses.optionalViews !== "undefined")  {
      let arRte = (
        <Route path="/:productName/analyses/:analysisName" key="rte-analysis-result">
          <InvokeAnalysisResult />
        </Route>
      );
      routeList.push(arRte);

      // Sort the menu items in alphabetical order by name.
      let lnks = this.state.licenses.optionalViews.slice().sort();
      // Sort the routes in desending order by character length.
      let rtes = lnks.slice().sort(
        (el1, el2) => {return(el1.length > el2.length ? -1 : 1)}
      );

      for (var i = 0; i < lnks.length; ++i) {
        if (typeof this.menuProducts[lnks[i]] !== "undefined") {
          let ellnk = (
            <NavDropdown.Item key={"lnk-" + lnks[i]} as={Link} to={"/" + lnks[i]}>
              {this.menuProducts[lnks[i]].icon}
              &nbsp;&nbsp;
              {lnks[i]}
            </NavDropdown.Item>
          );
          linkList.push(ellnk);
        }
      }

      for (i = 0; i < rtes.length; ++i) {
        if (typeof this.menuProducts[rtes[i]] !== "undefined") {
          let elrte = (
            <Route path={"/" + rtes[i]} key={"rte-" + rtes[i]}>
              {this.menuProducts[rtes[i]].component}
            </Route>
          )
          routeList.push(elrte);
        }
      }

      const defaultRoute = (
        <Route path="/" key="rte-default">
          <InvokeWelcome />
        </Route>
      );
      routeList.push(defaultRoute);
    } else {
      let ellnk = (
        <NavDropdown.Item key={"lnk-loading"}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          &nbsp;&nbsp;Loading product list...
        </NavDropdown.Item>
      );
      linkList.push(ellnk);

      let elrte = (
        <Route path="/" key="rte-default">
          <Container className="my-5">
            <Row>
              <Col className="text-center">
                <h1>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  &nbsp;&nbsp;Initializing application...
                </h1>
              </Col>
            </Row>
          </Container>
        </Route>
      );
      routeList.push(elrte);
    }

    return(
      <Router history={history}>
        <Navbar sticky="top" bg="secondary">
          <Container fluid>
            <Navbar.Brand>&nbsp;&nbsp;&nbsp;<img src={logo} height="40" width="103" alt="Invoke Learning" title="Invoke Learning" /></Navbar.Brand>
            <Nav>
              <Nav.Item>
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
              </Nav.Item>
              <NavDropdown title="Products" id="clairity-product-dropdown">
                {linkList}
              </NavDropdown>
              <NavDropdown title={"Signed in as " + this.context.user.name} id="clairity-auth-dropdown">
                <NavDropdown.Item
                  key="lnk-logout"
                  as={Link}
                  to="#" onClick={() => this.context.logout({returnTo: window.location.origin})}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Container>
        </Navbar>
        <Switch>
          {routeList}
        </Switch>
      </Router>
    );
  }
}

export default App;