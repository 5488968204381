import React from 'react';

import {
  Container,
  Card,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  Spinner
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingCart,
  faUserFriends,
  faCartPlus,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';

import Fuse from 'fuse.js';

import InvokeAnalysisConfigurationItem from "./InvokeAnalysisConfigurationItem";
import InvokeAnalysisInitiator from './InvokeAnalysisInitiator';
import coerceConfigStateToNullableBoolean from '../Utilities/CoerceConfigStateToNullableBoolean';

class InvokeAnalysisConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.fuseOptions = {
      shouldSort: true,
      tokenize: true,
      threshold: 0.4,
      location: 0,
      distance: 1000,
      maxPatternLength: 50,
      minMatchCharLength: 1,
      keys: ["name", "description", "tags"],
    };

    this.repopulatedAt = null;
    this.state = {
      dataGroupSearchField: "",
      cohortSearchField: ""
    };

    this.handleDataGroupSearchChange = this.handleDataGroupSearchChange.bind(this);
    this.handleCohortSearchChange = this.handleCohortSearchChange.bind(this);
  }

  handleDataGroupSearchChange(e) {
    this.setState({dataGroupSearchField: e.target.value});
  }

  handleCohortSearchChange(e) {
    this.setState({cohortSearchField: e.target.value});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Reset the search field when abandoning unsaved changes.
    if (prevProps.lastAnalysisConfigRetrieval < this.props.lastAnalysisConfigRetrieval) {
      this.setState(
        {
          dataGroupSearchField: "",
          cohortSearchField: ""
        }
      );
    }
  }

  render() {

    var inUseDataGroupElement;
    var availableDataGroupElement;
    var inUseCohortElement;
    var availableCohortElement;

    if (
      (this.props.dataGroups !== null) &&
      (this.props.cohorts !== null)
    ) {

      let inUseDataGroupItems = [];
      let availableDataGroupItems = [];

      this.props.dataGroups.forEach(
        (x) => {
          if (x.selected) {
            inUseDataGroupItems.push(x);
          } else {
            availableDataGroupItems.push(x);
          }
        }
      );

      const dataGroupCompareFn = (a, b) => {

        if (a.data_group > b.data_group) {
          return 1;
        }

        if (a.data_group < b.data_group) {
          return -1;
        }

        return 0;
      };

      inUseDataGroupItems.sort(dataGroupCompareFn);
      availableDataGroupItems.sort(dataGroupCompareFn);

      let filteredAvailableDataGroupItems = availableDataGroupItems;

      if (this.state.dataGroupSearchField !== "") {
        const fuse = new Fuse(
          availableDataGroupItems,
          this.fuseOptions
        );

        filteredAvailableDataGroupItems = fuse.search(
          this.state.dataGroupSearchField
        ).map(
          (x) => x.item
        );
      }

      if (inUseDataGroupItems.length === 0) {
        inUseDataGroupItems = (
          <p>Select data groups from the available list.</p>
        )
      } else {
        inUseDataGroupItems = inUseDataGroupItems.map(
          (x, idx) => {
            return(
              <InvokeAnalysisConfigurationItem
                key={`inUse-dataGroup-${idx}`}
                type="inUse-dataGroup"
                inUse={true}
                itemName={x.data_group}
                isEnabled={coerceConfigStateToNullableBoolean("dataGroup", x.state)}
                description={x.description}
                tags={x.tags}
                configurationChangeHandler={this.props.configurationChangeHandler}
              />
            );
          }
        );
      }

      filteredAvailableDataGroupItems = filteredAvailableDataGroupItems.map(
        (x, idx) => {
          return(
            <InvokeAnalysisConfigurationItem
              key={`available-dataGroup-${idx}`}
              type="available-dataGroup"
              inUse={false}
              itemName={x.data_group}
              isEnabled={coerceConfigStateToNullableBoolean("dataGroup", x.state)}
              description={x.description}
              tags={x.tags}
              configurationChangeHandler={this.props.configurationChangeHandler}
            />
          );
        }
      );

      inUseDataGroupElement = (
        <Container>
          <Row>
            <Col>
              <h5><FontAwesomeIcon icon={faShoppingCart} />&nbsp;&nbsp;Data Groups</h5>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              {inUseDataGroupItems}
            </Col>
          </Row>
        </Container>
      );

      availableDataGroupElement = (
        <Container>
          <Row>
            <Col>
              <h5><FontAwesomeIcon icon={faCartPlus} />&nbsp;&nbsp;Available Data Groups</h5>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <Form as="div">
                <Form.Group controlId="available-data-groups-search">
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.handleDataGroupSearchChange}
                    value={this.state.dataGroupSearchField}
                  />
                  <Form.Text className="text-muted">
                    Search Data Groups
                  </Form.Text>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="pt-1">
            <Col>
              {filteredAvailableDataGroupItems}
            </Col>
          </Row>
        </Container>
      );
    } else {
      inUseDataGroupElement = [
        <h5 className="my-3" key="loading-data-groups">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          &nbsp;&nbsp;Loading data groups...
        </h5>
      ];

      availableDataGroupElement = [
        <h5 className="my-3" key="loading-available-data-groups">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          &nbsp;&nbsp;Loading available data groups...
        </h5>
      ];
    }

    if (this.props.cohorts !== null) {

      let inUseCohortItems = [];
      let availableCohortItems = [];

      this.props.cohorts.forEach(
        (x) => {
          if (x.selected) {
            inUseCohortItems.push(x);
          } else {
            availableCohortItems.push(x);
          }
        }
      );

      const cohortCompareFn = (a, b) => {

        if (a.sort > b.sort) {
          return 1;
        }

        if (a.sort < b.sort) {
          return -1;
        }

        return 0;
      };

      inUseCohortItems.sort(cohortCompareFn);
      availableCohortItems.sort(cohortCompareFn);

      let filteredAvailableCohortItems = availableCohortItems;

      if (this.state.cohortSearchField !== "") {
        const fuse = new Fuse(
          availableCohortItems,
          this.fuseOptions
        );

        filteredAvailableCohortItems = fuse.search(
          this.state.cohortSearchField
        ).map(
          (x) => x.item
        );
      }

      inUseCohortItems = inUseCohortItems.map(
        (x, idx) => {
          return(
            <InvokeAnalysisConfigurationItem
              key={`inUse-cohort-${idx}`}
              type="inUse-cohort"
              inUse={true}
              itemName={x.name}
              isEnabled={coerceConfigStateToNullableBoolean("cohort", x.state)}
              description={x.description}
              tags={x.tags}
              configurationChangeHandler={this.props.configurationChangeHandler}
            />
          );
        }
      );

      filteredAvailableCohortItems = filteredAvailableCohortItems.map(
        (x, idx) => {
          return(
            <InvokeAnalysisConfigurationItem
              key={`available-cohort-${idx}`}
              type="available-cohort"
              inUse={false}
              itemName={x.name}
              isEnabled={coerceConfigStateToNullableBoolean("cohort", x.state)}
              description={x.description}
              tags={x.tags}
              configurationChangeHandler={this.props.configurationChangeHandler}
            />
          );
        }
      );

      if (inUseCohortItems.length === 0) {
        inUseCohortItems = (
          <p>All available data.</p>
        );
      }

      inUseCohortElement = (
        <Container>
          <Row>
            <Col>
              <h5><FontAwesomeIcon icon={faUserFriends} />&nbsp;&nbsp;Cohorts</h5>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              {inUseCohortItems}
            </Col>
          </Row>
        </Container>
      );

      availableCohortElement = (
        <Container>
          <Row>
            <Col>
              <h5><FontAwesomeIcon icon={faUserPlus} />&nbsp;&nbsp;Available Cohorts</h5>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <Form as="div">
                <Form.Group controlId="available-cohorts-search">
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.handleCohortSearchChange}
                    value={this.state.cohortSearchField}
                  />
                  <Form.Text className="text-muted">
                    Search Cohorts
                  </Form.Text>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="pt-1">
            <Col>
                {filteredAvailableCohortItems}
            </Col>
          </Row>
        </Container>
      );
    } else {
      inUseCohortElement = [
        <h5 className="my-3" key="loading-cohorts">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          &nbsp;&nbsp;Loading cohorts...
        </h5>
      ];

      availableCohortElement = [
        <h5 className="my-3" key="loading-available-cohorts">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          &nbsp;&nbsp;Loading available cohorts...
        </h5>
      ];
    }

    let dataGroupTitle = (
      <span><FontAwesomeIcon icon={faShoppingCart} />&nbsp;&nbsp;Data Groups</span>
    );

    let cohortTitle = (
      <span><FontAwesomeIcon icon={faUserFriends} />&nbsp;&nbsp;Cohorts</span>
    );

    return(
      <Card>
        <Card.Body>
          <Card.Title>Configuration</Card.Title>
          <Container>
            <Row>
              <Col>
                <Tabs defaultActiveKey="dataGroups" id="analysis-configuration-tabs">
                  <Tab eventKey="dataGroups" title={dataGroupTitle}>
                    <Container className="py-4">
                      <Row>
                        <Col>{inUseDataGroupElement}</Col>
                        <Col>{availableDataGroupElement}</Col>
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="cohort" title={cohortTitle}>
                    <Container className="py-4">
                      <Row>
                        <Col>{inUseCohortElement}</Col>
                        <Col>{availableCohortElement}</Col>
                      </Row>
                    </Container>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
            <Row className="py-2">
              <Col>
                <Container>
                  <Row >
                    <Col>
                      <InvokeAnalysisInitiator 
                        analysisRunState={this.props.analysisRunState}
                        saveConfigurationChangesHandler={this.props.saveConfigurationChangesHandler}
                        undoConfigurationChangesHandler={this.props.undoConfigurationChangesHandler}
                        analysisStartHandler={this.props.analysisStartHandler}
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  }
}

export default InvokeAnalysisConfiguration;