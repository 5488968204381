import React from 'react';

import {
  withRouter
} from "react-router-dom";


import { Auth0Context } from "@auth0/auth0-react";

import {
  Container,
  Row,
  Col,
  Spinner
} from 'react-bootstrap';

import InvokeAnalysisResultSummary from "./InvokeAnalysisResultSummary";
import InvokeAnalysisResultFactorProfile from "./InvokeAnalysisResultFactorProfile";
import InvokeAnalysisResultInfo from "./InvokeAnalysisResultInfo";
import formatTimestamp from '../Utilities/FormatTimestamp';

import api_url from "../api_url";

class InvokeAnalysisResult extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);

    this.state = {
      jrrToken: null,
      dashboardId: null,
      analysisResult: null
    };

  }

  componentDidMount() {
    const { getAccessTokenSilently } = this.context;
    getAccessTokenSilently({
      audience: api_url,
    }).then((t) => {
      this.setState({ jrrToken: "Bearer " + t });
      this.initializeData();
    }).catch((e) => {
      console.log(e);
    });
  }


  initializeData() {

    const username = this.context.user.name;
    const productName = this.props.match.params.productName;
    const analysisName = this.props.match.params.analysisName;

    const options = {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken
      }
    };

    fetch(
      `${api_url}/analyses/${username}/${productName}/${analysisName}`,
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {
        this.setState(
          {
            analysisResult: data
          }
        );
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  render() {
    const username = this.context.user.name;
    const productName = this.props.match.params.productName;
    const analysisName = this.props.match.params.analysisName;
    const analysisResult = this.state.analysisResult;

    if (analysisResult === null) {
      return (
        <Container className="my-5">
          <Row>
            <Col className="text-center">
              <h1>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                &nbsp;&nbsp;Loading analysis result...
              </h1>
            </Col>
          </Row>
        </Container>
      );
    }

    const analysisDate = formatTimestamp(analysisResult.created_date, "T");
    const productDescription = analysisResult.product_description;
    const dataGroups = analysisResult.analysis_config.data_groups;
    const cohorts = analysisResult.analysis_config.cohorts;
    const dataSources = analysisResult.data_sources || [];
    const dashboardId = analysisResult.dashboard_id;

    let analysisLabel = ": unlabeled";
    if (
      (typeof analysisResult.label !== "undefined")
      && (analysisResult.label !== null)
    ){
      analysisLabel = `: ${analysisResult.label}`;
    }

    return (
      <Container fluid>
        <Row className="pt-3 px-2">
          <Col>
            <h2>Results for {productName} Analysis {analysisLabel}</h2>
          </Col>
        </Row>
        <Row className="py-2">
          <Col>
            <InvokeAnalysisResultSummary
              analysisResult={analysisResult}
            />
          </Col>
        </Row>
        <Row className="py-2">
          <Col>
            <InvokeAnalysisResultFactorProfile
              analysisName={analysisName}
              dashboardId={dashboardId}
              productName={productName}
              username={username}
            />
          </Col>
        </Row>
        <Row className="py-2">
          <Col>
            <InvokeAnalysisResultInfo
              productName={productName}
              productDescription={productDescription}
              analysisDate={analysisDate}
              dataGroups={dataGroups}
              cohorts={cohorts}
              dataSources={dataSources}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(InvokeAnalysisResult);