import React from 'react';

import {
    Button
  } from 'react-bootstrap';
  
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import {
    faSave,
    faUndo,
    faPlay,
    faSync
  } from '@fortawesome/free-solid-svg-icons';

class InvokeAnalysisInitiator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var operationButtons;

    switch (this.props.analysisRunState) {
      case  "NEEDS_SAVE":
        operationButtons = (
          <span>
            <Button variant="primary" onClick={this.props.saveConfigurationChangesHandler}>
              <FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Save Changes
            </Button>
            &nbsp;&nbsp;
            <Button variant="primary" onClick={this.props.undoConfigurationChangesHandler}>
              <FontAwesomeIcon icon={faUndo} />&nbsp;&nbsp;Undo Changes
            </Button>
          </span>
        );
        break;
      case "REQUESTED":
      case "RUNNING":
        operationButtons = (
          <Button variant="primary" disabled>
            <FontAwesomeIcon icon={faSync} />&nbsp;&nbsp;Analysis In-Progress
          </Button>
        );
        break;
      case "IDLE":
        operationButtons = (
          <Button variant="primary" onClick={this.props.analysisStartHandler}>
            <FontAwesomeIcon icon={faPlay} />&nbsp;&nbsp;Run Analysis
          </Button>
        );
        break;
      case "NEEDS_DATA_GROUP":
          operationButtons = (
            <>
            </>
          );
          break;
      default:
        console.log(`InvokeAnalysisInitiator does not recognize ${this.props.analysisRunState} as a valid value.`)
        break;
    }

    return(
      <div>{operationButtons}</div>
    );
  }
}

export default InvokeAnalysisInitiator;