import React from 'react';

import { Auth0Context } from "@auth0/auth0-react";

import {
  Container,
  Row,
  Col,
  Toast,
  ToastContainer
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faTrashAlt,
  faBan
} from '@fortawesome/free-solid-svg-icons';

import api_url from "../api_url";

import InvokeAnalysisDescription from './InvokeAnalysisDescription';
import InvokeAnalysisConfiguration from './InvokeAnalysisConfiguration';
import InvokeAnalysisList from './InvokeAnalysisList';
import InvokeConfirmationModal from './InvokeConfirmationModal';

class InvokeAnalysisProduct extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);

    this.analysisPollInterval = null;

    this.runningStates = [
      "RAW",
      "STAGING",
      "EXTRACTING",
      "ANALYZING",
      "PROCESSING",
      "POSTPROCESSING"
    ];

    this.analysisDeleteConfirmBody = (
      "Deleting an analysis is irreversible. Please verify that " +
      "you will have no further need to access its configuration " +
      "or results before proceeding."
    )

    this.analysisDeleteConfirmProceed = (
      <>
        <FontAwesomeIcon icon={faTrashAlt} />&nbsp;&nbsp;Delete Analysis
      </>
    );

    this.analysisDeleteConfirmAbort = (
      <>
        <FontAwesomeIcon icon={faBan} />&nbsp;&nbsp;Cancel
      </>
    );

    this.state = {
      jrrToken: null,
      analysisRunState: "IDLE",
      analyses: null,
      configChanged: false,
      productName: null,
      productDescription: null,
      dataGroups: null,
      cohorts: null,
      collectionIngredients: null,
      collectionTargets: null,
      collectionFilters: null,
      configurationAlertMessage: "",
      configurationAlertVisible: false,
      analysisDeleteConfirmVisible: false,
      deleteAnalysisName: null
    };

    this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    this.handleSaveConfigurationChanges = this.handleSaveConfigurationChanges.bind(this);
    this.handleUndoConfigurationChanges = this.handleUndoConfigurationChanges.bind(this);
    this.handleAnalysisLabelSave = this.handleAnalysisLabelSave.bind(this);
    this.handleAnalysisSettingsLoad = this.handleAnalysisSettingsLoad.bind(this);
    this.handleAnalysisStart = this.handleAnalysisStart.bind(this);
    this.handleAnalysisDeleteConfirm = this.handleAnalysisDeleteConfirm.bind(this);
    this.handleAnalysisDeleteProceed = this.handleAnalysisDeleteProceed.bind(this);
    this.handleAnalysisDeleteAbort = this.handleAnalysisDeleteAbort.bind(this);
  }

  handleConfigurationChange(itemProps, op) {
    let type = itemProps.type;
    let [state, entity] = type.split("-");

    if (["dataGroup", "cohort"].indexOf(entity) === -1) {
      throw new Error(`The value "${entity}" is not a valid entity name.`);
    }

    // Block change events when analysis is in process.
    if (
      this.state.analysisRunState === "REQUESTED" ||
      this.state.analysisRunState === "RUNNING"
    ) {
      return;
    }

    const newEntityList = [];
    const entityKey = `${entity}s`;
    const stateObject = {analysisRunState: "NEEDS_SAVE"};

    const configNameKeyMap = {
      dataGroup: "data_group",
      cohort: "name"
    };

    switch (op) {
      case "addRemove":
        this.state[entityKey].forEach(
          (x) => {
            let x_deep_copy = JSON.parse(JSON.stringify(x));
            if (x[configNameKeyMap[entity]] === itemProps.itemName) {
              switch(state) {
                case "inUse":
                  x_deep_copy.selected = false;
                  x_deep_copy.state = null;
                  break;
                case "available":
                  x_deep_copy.selected = true;
                  switch (entity) {
                    case "dataGroup":
                      x_deep_copy.state = "active";
                      break;
                    case "cohort":
                      x_deep_copy.state = "include";
                      break;
                    default:
                      throw new Error(`The value ${entity} is not a valid entity.`);
                  }
                  break;
                default:
                  throw new Error(`The value ${state} is not a valid state.`);
              }
            }
            newEntityList.push(x_deep_copy);
          }
        );

        stateObject[entityKey] = newEntityList;
        break;
      case "toggleOn":
      case "toggleOff":
        this.state[entityKey].forEach(
          (x) => {
            let x_deep_copy = JSON.parse(JSON.stringify(x));
            if (x[configNameKeyMap[entity]] === itemProps.itemName) {
              if (op === "toggleOn") {
                switch (entity) {
                  case "dataGroup":
                    x_deep_copy.state = "active";
                    break;
                  case "cohort":
                    x_deep_copy.state = "include";
                    break;
                  default:
                    throw new Error(`The value ${entity} is not a valid entity.`);
                }
              } else {
                switch(entity) {
                  case "dataGroup":
                    x_deep_copy.state = "inactive";
                    break;
                  case "cohort":
                    x_deep_copy.state = "exclude";
                    break;
                  default:
                    throw new Error(`The value ${entity} is not a valid entity.`);
                }
              }
            }
            newEntityList.push(x_deep_copy);
          }
        );
        stateObject[entityKey] = newEntityList;
        break;
      default:
        console.log("Unrecognized configuration change operation.");
        break;
    }
    // Keep users from initaiting analyses without data groups selected.
    if (!this.hasSelectedDataGroups(stateObject.dataGroups)) {
      stateObject.analysisRunState = "NEEDS_DATA_GROUP";
    }

    this.setState(stateObject);
  }

  handleUndoConfigurationChanges() {
    this.getProductConfig(this.state.productName);
    this.setState(
      {
        analysisRunState: "IDLE",
        configurationAlertVisible: true,
        configurationAlertMessage: "Resetting analysis configuration."
      }
    );
    const that = this;
    setTimeout(
      () => {
        that.setState(
          {
            configurationAlertVisible: false,
            configurationAlertMessage: ""
          }
        );
      },
      3000
    );
  }

  handleSaveConfigurationChanges() {
    this.saveAnalysisConfig("Configuration changes have been saved.");
  }

  handleAnalysisLabelSave(analysisName, label) {
    this.saveAnalysisLabel(analysisName, label);
  }

  handleAnalysisSettingsLoad(analysisName) {
    this.loadAnalysisSettings(analysisName);
  }

  handleAnalysisStart() {
    this.setState({analysisRunState: "REQUESTED"});
    this.startAnalysis();
  }

  handleAnalysisDeleteConfirm(analysisName) {
    this.setState(
      {
        analysisDeleteConfirmVisible: true,
        deleteAnalysisName: analysisName
      }
    );
  }

  handleAnalysisDeleteProceed() {
    this.deleteAnalysis(this.state.deleteAnalysisName);
    this.setState(
      {
        analysisDeleteConfirmVisible: false,
        deleteAnalysisName: null,
        configurationAlertVisible: true,
        configurationAlertMessage: "Deleting analysis."
      }
    );

    const that = this;

    setTimeout(
      () => {
        that.setState(
          {
            configurationAlertVisible: false,
            configurationAlertMessage: ""
          }
        );
      },
      3000
    );
  }

  handleAnalysisDeleteAbort() {
    this.setState(
      {
        analysisDeleteConfirmVisible: false,
        deleteAnalysisName: null
      });
  }

  componentDidMount() {
    const {getAccessTokenSilently} = this.context;
    getAccessTokenSilently({
      audience: api_url,
    }).then((t) => {
      this.setState({ jrrToken: "Bearer " + t });
      this.initializeData();
    }).catch((e) => {
     console.log(e);
    });
  }

  componentWillUnmount() {
    clearInterval(this.analysisPollInterval);
    this.analysisPollInterval = null;
  }

  initializeData() {
    this.setState(
      {
        analysisRunState: "IDLE",
        productName: null,
        productDescription: null,
        dataGroups: null,
        cohorts: null
      }
    );

    this.getProductConfig();
  }

  getProductConfig() {

    const productName = this.props.product;
    const username = this.context.user.name;

    const options = {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken
      }
    };

    fetch(
      `${api_url}/product/${username}/${productName}`,
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {

        const stateObject = {
          productName: productName,
          productDescription: data.product_description,
          dataGroups: data.data_groups,
          cohorts: data.cohorts
        };

        if (!this.hasSelectedDataGroups(data.data_groups)) {
          stateObject.analysisRunState = "NEEDS_DATA_GROUP";
        }
        this.setState(stateObject);
        this.getAnalyses(username, productName, true);
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  getAnalyses(username, productName, continueChain) {

    const options = {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken
      }
    };

    fetch(
      `${api_url}/analyses_list/${username}/${productName}`,
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {
        // Preserve backward compatibility by transforming
        // analysis list from Object to Array.
        if ((typeof data === "object") && (data !== null)) {
          const analyses = [];

          Object.getOwnPropertyNames(data).forEach(
            (x) => {
              analyses.push(data[x]);
            }
          );
          this.setState({analyses: analyses});
        } else {
          this.setState({analyses: []});
        }

        if (this.state.analysisRunState === "REQUESTED") {
          let runningStateSeen = false;
          this.state.analyses.forEach(
            (a) => {
              if (this.runningStates.indexOf(a.status) !== -1) {
                this.setState({analysisRunState: "RUNNING"});
                runningStateSeen = true;
              }
            }
          );
          if (!runningStateSeen) {
            let analyses = JSON.parse(JSON.stringify(this.state.analyses));
            analyses.reverse();
            analyses.push(
              {
                accuracy: "",
                created_date: (new Date()).toISOString(),
                label: "",
                status: "RAW"
              }
            );
            analyses.reverse();
            this.setState({analyses: analyses});
          }
        } else {
          let runningStateSeen = false;
          this.state.analyses.forEach(
            (a) => {
              if (this.runningStates.indexOf(a.status) !== -1) {
                runningStateSeen = true;
                this.setState({analysisRunState: "RUNNING"});
              }
            }
          );
          if (!runningStateSeen && (this.state.analysisRunState !== "NEEDS_SAVE")) {
            if (this.hasSelectedDataGroups(this.state.dataGroups)) {
              this.setState({analysisRunState: "IDLE"});
            } else {
              this.setState({analysisRunState: "NEEDS_DATA_GROUP"});
            }
          }
        }
        if (continueChain) {
          if (this.analysisPollInterval === null) {
            this.analysisPollInterval = setInterval(
              () => {
                this.getAnalyses(username, productName, false);
              },
              2000
            );
          }
        }
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  saveAnalysisConfig(message) {

    const username = this.context.user.name;
    const productName = this.state.productName;

    const bodyObj = {
      description: this.state.productDescription,
      data_groups: this.state.dataGroups,
      cohorts: this.state.cohorts
    };

    const bodyBlob = new Blob([JSON.stringify(bodyObj)]);

    const options = {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken
      },
      body: bodyBlob
    };

    fetch(
      `${api_url}/product/${username}/${productName}`,
      options
    ).then(
      (data) => {
        this.setState(
          {
            analysisRunState: "IDLE",
            configurationAlertVisible: true,
            configurationAlertMessage: message
          }
        );
        const that = this;
        setTimeout(
          () => {
            that.setState(
              {
                configurationAlertVisible: false,
                configurationAlertMessage: ""
              }
            );
          },
          3000
        )
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  saveAnalysisLabel(analysisName, label) {

    const username = this.context.user.name;
    const productName = this.state.productName;

    const bodyObj = {
      new_label: label
    };

    const bodyBlob = new Blob([JSON.stringify(bodyObj)]);

    const options = {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken
      },
      body: bodyBlob
    };

    fetch(
      `${api_url}/analyses_label/${username}/${productName}/${analysisName}`,
      options
    ).then(
      (data) => {
        return;
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  loadAnalysisSettings(analysisName) {

    const username = this.context.user.name;
    const productName = this.state.productName;

    const options = {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken
      }
    };

    fetch(
      `${api_url}/analysis_config/${username}/${productName}/${analysisName}`,
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {
        this.setState(
          {
            productDescription: data.product_description,
            dataGroups: data.data_groups,
            cohorts: data.cohorts
          }
        );
        this.saveAnalysisConfig("Analysis configuration has been loaded.");
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  startAnalysis() {

    const username = this.context.user.name;
    const productName = this.state.productName;

    const options = {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken
      }
    };

    fetch(
      `${api_url}/analyses_run/${username}/${productName}`,
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {
        console.log(
          "Starting analysis: " +
          `analysis_name = ${data.analysis_name}, ` +
          `job_id = ${data.job_id}, ` +
          `version = ${data.version}`
        );
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  deleteAnalysis(analysisName) {

    const username = this.context.user.name;
    const productName = this.state.productName;

    const options = {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken
      }
    };

    fetch(
      `${api_url}/analyses/${username}/${productName}/${analysisName}`,
      options
    ).then(
      (data) => {
        return;
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  hasSelectedDataGroups(dataGroups) {
    for (let i = 0; i < dataGroups.length; i++) {
      if (dataGroups[i].selected === true) {
        return true;
      }
    }
    return false;
  }

  render() {
    return(
      <Container>
        <InvokeConfirmationModal
          show={this.state.analysisDeleteConfirmVisible}
          title="Confirm Analysis Deletion"
          body={this.analysisDeleteConfirmBody}
          confirmText={this.analysisDeleteConfirmProceed}
          cancelText={this.analysisDeleteConfirmAbort}
          confirmHandler={this.handleAnalysisDeleteProceed}
          cancelHandler={this.handleAnalysisDeleteAbort}
        />
        <Row className="my-2">
          <Col>
            <InvokeAnalysisDescription
              productName={this.state.productName}
              productDescription={this.state.productDescription}
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <InvokeAnalysisConfiguration
              dataGroups={this.state.dataGroups}
              cohorts={this.state.cohorts}
              configurationChangeHandler={this.handleConfigurationChange}
              lastAnalysisConfigRetrieval={this.state.lastAnalysisConfigRetrieval}
              analysisRunState={this.state.analysisRunState}
              saveConfigurationChangesHandler={this.handleSaveConfigurationChanges}
              undoConfigurationChangesHandler={this.handleUndoConfigurationChanges}
              analysisStartHandler={this.handleAnalysisStart}
            />
            <ToastContainer>
              <Toast show={this.state.configurationAlertVisible}>
                <Toast.Header closeButton={false}>
                  Configuration Message
                </Toast.Header>
                <Toast.Body>
                  {this.state.configurationAlertMessage}
                </Toast.Body>
              </Toast>
            </ToastContainer>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <InvokeAnalysisList
              product={this.props.product}
              analyses={this.state.analyses}
              labelSaveHandler={this.handleAnalysisLabelSave}
              settingsLoadHandler={this.handleAnalysisSettingsLoad}
              analysisDeleteConfirmHandler={this.handleAnalysisDeleteConfirm}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default InvokeAnalysisProduct;